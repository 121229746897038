import ModuleFactory from '@/store/modules/moduleFactory'
import { apiDataMorpherV2 } from "@/utils/apiDataHelper"

const { Repository, namespaced, state, getters, actions, mutations } = ModuleFactory.createModule('leads')

const SET_ELEMENT = 'set_element'
const SET_ELEMENT_RESPONSE = 'set_element_response'
export const LISTV2 = 'loadv2'
export const GETV2 = 'getv2'

const extendedActions = {
  ...actions,
  [LISTV2]({ commit }: any, queryParams?: { [key:string]: any }) {
    return Repository.listV2(queryParams)
  },
  async [GETV2]({ commit }: any, payload: {id: string, queryParams?: { [key:string]: any }}) {
    const queryParams = payload.queryParams ?? {}
      queryParams['filter[id]'] = payload.id
      const responseData = await Repository.getV2(queryParams)
      if (queryParams?.include) {
        apiDataMorpherV2(responseData, queryParams.include)
      }
      commit(SET_ELEMENT_RESPONSE, responseData)
      commit(SET_ELEMENT, responseData.data[0])
      return responseData.data[0]
  }
}

export default {
  namespaced,
  state: state,
  getters,
  actions: extendedActions,
  mutations: mutations,
}
