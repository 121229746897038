import { set, union, camelCase } from 'lodash'
import { CollectionResourceDoc, ResourceIdentifierObject, RelationshipsWithData, isRelationshipWithData } from '@/models/jsonapi'

// export default {
//   apiDataMorpher,
//   extractRelationshipIds,
//   extractIncludedTypes,
//   modelTranslation,
//   apiDataMerger
// }

export function localIndex(response: any) {
  const index = {}
  const data = response.data.concat(response.included || [])
  const addToIndex = (entry:any) => {index[entry.id] = entry}
  if (data && data.length > 0) data.forEach(addToIndex)
  return index
}

export function fetchRelated(origin:any, path:string, index:any) {
  const id = fetchRelatedId(origin, path, index)
  return index[id]
}

export function fetchRelatedId(origin:any, path:string, index:any) {
  const dotIndex = path.indexOf('.')
  const nextType = dotIndex === -1 ? path : path.slice(0, dotIndex)
  const data = index[origin?.relationships?.[nextType]?.data?.id]
  // console.log('path: ',path, 'origin: ',origin)
  if (Array.isArray(data)) {
    return data.map((ro:any) => {return dotIndex === -1 ? ro.id : fetchRelatedId(ro, path.slice(dotIndex+1), index)})
  } else if (typeof(data) === 'object') {
    return dotIndex === -1 ? data.id : fetchRelatedId(data, path.slice(dotIndex+1), index)
  } else if (data === undefined) {
    console.log('path: ',path, 'origin: ',origin)
    console.log('data is undefined in fetchRelatedId')
    return
  } else {
    console.log('unexpected data in fetchRelatedId: ', origin, nextType)
    // alert('traverseFetchId error')
  }
}

export function apiDataMorpher(data: any, include: string) {
  const includedTypes = include.split(',')
  if (data.included && data.included.length > 0) {
    const reducer = (res, cv) => {res[cv.id] = cv; return res}
    const localIndex = data.included.reduce(reducer, {})
    data.data.forEach((item) => {
      const rels = item.relationships
      includedTypes.forEach( (type) => {
        if (Array.isArray(rels[type].data)) {
          item.attributes[type] = rels[type].data.map((rel) => localIndex[rel.id])
        } else {
          if (Object.keys(rels[type]).length>0) {
            item.attributes[type] = localIndex[rels[type].data.id]
          }
        }
      })
    })
  }
}

export function apiDataMorpherV2(data: any, include: string) {
  const includedTypes = include.split(',').map(type => type.split('.')[0])
  const nestedIncludedTypes = include.split(',').filter(type => type.includes('.'))
  if (data.included && data.included.length > 0) {
    const reducer = (res, cv) => {res[cv.id] = cv; return res}
    const localIndex = data.included.reduce(reducer, {})
    data.data.forEach((item) => {
      const rels = item.relationships
      includedTypes.forEach( (type) => {
        if (Array.isArray(rels[type]?.data)) {
          item.attributes[type] = rels[type].data.map((rel) => localIndex[rel.id])
        } else {
          if (Object.keys(rels[type])?.length) {
            item.attributes[type] = localIndex[rels[type]?.data?.id]
          }
        }
      })
      nestedIncludedTypes.forEach( (type: string) => {
        const innerItem = item.attributes[type.split('.').at(0)!]
        const innerType = type.split('.').at(-1)
        const innerRelIds = innerItem.relationships[innerType!].data.map(rel => rel.id)
        const innerIncludedItems = data.included.filter(inc => innerRelIds.includes(inc.id)).map(inc => inc.attributes)         
        set(item.attributes, type.split('.').join('.attributes.'), innerIncludedItems)
      })
    })
  }
}


// export function extractRelationshipIds<T>(response: CollectionResourceDoc<string, T>, type: string): string[] {
export function extractRelationshipIds(response: any, type: string): string[] {
  return response.data
    .map(element => element.relationships?.[type])
    .filter(relationship => {
      if (!relationship) return false
      if (!isRelationshipWithData(relationship)) return false
      const relationshipData = relationship.data
      if (!relationshipData) return false
      return true
    })
    .flatMap((relationship: RelationshipsWithData) => {
      const relationshipData = relationship.data as ResourceIdentifierObject|ResourceIdentifierObject[]
      return Array.isArray(relationshipData) ? relationshipData.map(r => r.id) : [relationshipData.id]
    })
}


export function extractIncludedTypes(response: any) {
  const res = {}
  response.included.forEach((el: any) => {
    if (!res[el.type]){ res[el.type] = [] }
    res[el.type].push(el)
  })
  return res
}

export function apiDataMerger(main: any, add: any, assoc: string) {
  const idx = {}
  add.data.forEach((posting: any) => idx[posting.id] = posting )
  main.data.forEach((visor: any) => {
    const id = visor.relationships[assoc].data.id
    visor.attributes[assoc] = idx[id]
  })
}

export function mapApiDataMerger(main: any, add: any, assoc: string) {
  const childrenIndex = new Map(add.map((child: any) => [child.id, child]))
  main.forEach((carrier: any) => {
    carrier.attributes[assoc] = childrenIndex.get(carrier.relationships[assoc].data.id)
  })
}

export const getRelationshipId = (data: any, type: string) => {
  if (!data) return ''
  const id = ((data.relationships?.[type] as RelationshipsWithData)?.data as ResourceIdentifierObject)?.id
  return id || ''
}

export const modelTranslation = (modelName: string):string => {
  const out: string|undefined = {
    'Seller': 'Vertrieb',
    'Assistant': 'Assistent',
    'Internal': 'Innendienst',
    'Provider': 'Agentur',
    //
    'Vertrieb': 'Seller',
    'Assistent': 'Assistant',
    'Innendienst': 'Internal',
    'Agentur': 'Provider',
    //
    'Household': 'Haushalt',
    'CareReceiver': 'Pflegebedürftige',
    'Lead': 'Lead',
    'Visor': 'Offene Stelle',
    'Posting': 'Stellenausschreibung',
    'Suggestion': 'Vorschlag',
    'Contract': 'Vertrag',
    //
    'Haushalt' : 'Household',
    'Offene Stelle' : 'Visor',
    'Stellenausschreibung' : 'Posting',
    'Vorschlag' : 'Suggestion',
  }[modelName]
  return out ? out : 'Unbekannt'
}

export const modelCaseConversion = (modelName: string):string => {
  const out: string|undefined = {
    'Household': 'households',
    'CareReceiver': 'care_receivers',
    'Lead': 'leads',
    'Visor': 'visors',
    'Posting': 'postings',
    'Suggestion': 'suggestions',
    'Contract': 'contracts',
    //
    'households': 'Household',
    'care_receivers': 'CareReceiver',
    'leads': 'Lead',
    'visors': 'Visor',
    'postings': 'Posting',
    'suggestions': 'Suggestion',
    'contracts': 'Contract',
  }[modelName]
  return out ? out : 'Unbekannt'
}