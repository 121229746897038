//@ts-nocheck
import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import Vue from "vue";

//Pro-Solid Style (fas)
import {
  faCommentExclamation as fasCommentExclamation,
  faSignalAltSlash as fasSignalAltSlash,
  faText as fasText,
  faTextSize as fasTextSize,
  faWifiSlash as fasWifiSlash,
  faCartPlus as fasCartPlus,
  faNewspaper as fasNewspaper,
  faCalendarCheck as fasCalendarCheck,
  faCalendarPlus as fasCalendarPlus,
  faInfoCircle as fasInfoCircle,
  faCog as fasCog,
} from '@fortawesome/pro-solid-svg-icons'

//Free-Regular Style (far)
import {
  faAngry as farAngry,
  faEye as farEye,
  faTimesCircle as farTimesCircle,
  faCircle as farCircle,
  faSave as farSave,
  faSmile as farSmile,
} from '@fortawesome/free-regular-svg-icons'

//Regular Style (far)
import {
  faBan as farBan,
  faHomeAlt as farHomeAlt,
  faBullhorn as farBullhorn,
  faSmokingBan as farSmokingBan,
  faInfoCircle as farInfoCircle,
  faMailbox as farMailbox,
} from '@fortawesome/pro-regular-svg-icons'

//Light Style (fal)
import {
  faBan as falBan,
  faCommentDollar as falCommentDollar,
  faEject as falEject,
  faEyeSlash as falEyeSlash,
  faFlagCheckered as falFlagCheckered,
  faMoneyCheck as falMoneyCheck,
  faNewspaper as falNewspaper,
  faPortalExit as falPortalExit,
  faQuestion as falQuestion,
  faSmile as falSmile,
  faSmokingBan as falSmokingBan,
  faTasks as falTasks,
} from '@fortawesome/pro-light-svg-icons'

// Duotone Style (fad)
import {
  faAddressCard as fadAddressCard,
  faAward as fadAward,
  faBell as fadBell,
  faBoxHeart as fadBoxHeart,
  faBug as fadBug,
  faCheckCircle as fadCheckCircle,
  faCopy as fadCopy,
  faExchange as fadExchange,
  faFileExclamation as fadFileExclamation,
  faFilePdf as fadFilePdf,
  faFileTimes as fadFileTimes,
  faCommentSlash as fadCommentSlash,
  faHourglassHalf as fadHourglassHalf,
  faPhonePlus as fadPhonePlus,
  faRandom as fadRandom,
  faSignalAlt as fadSignalAlt,
  faSignalAlt1 as fadSignalAlt1,
  faSignalAlt2 as fadSignalAlt2,
  faSignalAlt3 as fadSignalAlt3,
  faTrash as fadTrash,
  faUserCheck as fadUserCheck,
  faUserSlash as fadUserSlash,
  faUsersSlash as fadUsersSlash,
  faSync as fadSync,
  faUserTimes as fadUserTimes,
  faPeopleArrows as fadPeopleArrows,
  faInfoCircle as fadInfoCircle,
  faPoll as fadSquarePollHorizontal
} from '@fortawesome/pro-duotone-svg-icons'

// free-solid-svg-icons
import {
  faAddressBook,
  faAddressCard,
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faAngleRight,
  faAt,
  faBan,
  faBed,
  faBell,
  faBlind,
  faBold,
  faBolt,
  faBuilding,
  faBullhorn,
  faCarSide,
  faCity,
  faCloudSun,
  faCog,
  faCopy,
  faDatabase,
  faDiceOne,
  faDiceTwo,
  faDiceThree,
  faDiceFour,
  faDiceFive,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEquals,
  faExclamationTriangle,
  faExpand,
  faExpandArrowsAlt,
  faFileContract,
  faFileInvoiceDollar,
  faFilePdf,
  faFileSignature,
  faFont,
  faFunnelDollar,
  faGripHorizontal,
  faHeading,
  faHome,
  faHouseDamage,
  faHouseUser,
  faItalic,
  faLaptopHouse,
  faListOl,
  faListUl,
  faMapMarkerAlt,
  faMars,
  faMousePointer,
  faNetworkWired,
  faPaperclip,
  faPaperPlane,
  faPause,
  faPencilAlt,
  faPhone,
  faPlay,
  faPlus,
  faQuestion,
  faQuoteLeft,
  faRedo,
  faSearch,
  faSignal,
  faSlash,
  faSlidersH,
  faSmoking,
  faSmokingBan,
  faSort,
  faSortDown,
  faSortUp,
  faStar,
  faStarHalf,
  faStarHalfAlt,
  faStrikethrough,
  faSyncAlt,
  faTasks,
  faThermometerEmpty,
  faThermometerQuarter,
  faThermometerHalf,
  faThermometerThreeQuarters,
  faThermometerFull,
  faTicketAlt,
  faTimes,
  faTimesCircle,
  faCircle,
  faTransgender,
  faTrash,
  faUnderline,
  faUndo,
  faUser,
  faUserFriends,
  faUsers,
  faUserInjured,
  faUserPlus,
  faUserSecret,
  faUserTimes,
  faVenus,
  faWalking,
  faWheelchair,
  faWifi,
  faCrosshairs,
  faCheck,
  faCheckCircle,
  faEye,
  faFileUpload,
  faFileDownload,
  faUsersSlash,
  faDiagnoses,
  faPlaneArrival,
  faFlagCheckered,
  faRandom,
  faBox,
  faEyeSlash,
  faHandHoldingHeart,
  faBellSlash,
  faUserNurse,
  faHourglass,
  faHandshake,
  faEnvelopeOpenText,
  faPeopleArrows,
  faArchive,
} from '@fortawesome/free-solid-svg-icons'


export default function () {
  library.add(
    faAddressBook,
    faAddressCard,
    faAlignCenter,
    faAlignJustify,
    faAlignLeft,
    faAlignRight,
    faAngleRight,
    faAt,
    faBan,
    faBed,
    faBell,
    faBlind,
    faBolt,
    faBold,
    faBuilding,
    faBullhorn,
    faCarSide,
    faCity,
    faCloudSun,
    faCog,
    faCopy,
    faDatabase,
    faDiceOne,
    faDiceTwo,
    faDiceThree,
    faDiceFour,
    faDiceFive,
    faDownload,
    faEllipsisH,
    faEllipsisV,
    faEnvelope,
    faEquals,
    faExclamationTriangle,
    faExpand,
    faExpandArrowsAlt,
    faFileContract,
    faFileInvoiceDollar,
    faFilePdf,
    faFileSignature,
    faFont,
    faFunnelDollar,
    faGripHorizontal,
    faHeading,
    faHome,
    faHouseDamage,
    faHouseUser,
    faItalic,
    faLaptopHouse,
    faListOl,
    faListUl,
    faMousePointer,
    faMapMarkerAlt,
    faMars,
    faNetworkWired,
    faPaperclip,
    faPaperPlane,
    faPause,
    faPencilAlt,
    faPhone,
    faPlay,
    faPlus,
    faQuestion,
    faQuoteLeft,
    faRedo,
    faSearch,
    faSignal,
    faSlash,
    faSlidersH,
    faSmoking,
    faSmokingBan,
    faSort,
    faSortDown,
    faSortUp,
    faStar,
    faStarHalf,
    faStarHalfAlt,
    faStrikethrough,
    faSyncAlt,
    faTasks,
    faThermometerEmpty,
    faThermometerQuarter,
    faThermometerHalf,
    faThermometerThreeQuarters,
    faThermometerFull,
    faTicketAlt,
    faTimes,
    faTimesCircle,
    faTransgender,
    faCircle,
    faTrash,
    faUnderline,
    faUndo,
    faUser,
    faUsers,
    faUserFriends,
    faUserInjured,
    faUserPlus,
    faUserSecret,
    faUserTimes,
    faVenus,
    faWalking,
    faWheelchair,
    faWifi,
    fasCartPlus,
    faCrosshairs,
    faCheck,
    faCheckCircle,
    faEye,
    faFileUpload,
    faFileDownload,
    faUsersSlash,
    faDiagnoses,
    faPlaneArrival,
    faFlagCheckered,
    faRandom,
    faBox,
    faEyeSlash,
    faHandHoldingHeart,
    faBellSlash,
    faUserNurse,
    faHourglass,
    faHandshake,
    faEnvelopeOpenText,
    faPeopleArrows,
    faArchive,
    falBan,
    falCommentDollar,
    falEject,
    falEyeSlash,
    falFlagCheckered,
    falMoneyCheck,
    falNewspaper,
    falPortalExit,
    falQuestion,
    falSmile,
    falSmokingBan,
    falTasks,
    farAngry,
    farBan,
    farTimesCircle,
    farCircle,
    farEye,
    farHomeAlt,
    farBullhorn,
    farInfoCircle,
    farMailbox,
    farSave,
    farSmile,
    farSmokingBan,
    fasInfoCircle,
    fasCommentExclamation,
    fasNewspaper,
    fasSignalAltSlash,
    fasText,
    fasTextSize,
    fasWifiSlash,
    fadAddressCard,
    fadAward,
    fadBell,
    fadBoxHeart,
    fadBug,
    fadInfoCircle,
    fadSquarePollHorizontal,
    fasCalendarCheck,
    fasCalendarPlus,
    fasCog,
    fadCheckCircle,
    fadCopy,
    fadCommentSlash,
    fadFileExclamation,
    fadExchange,
    fadFilePdf,
    fadFileTimes,
    fadHourglassHalf,
    fadPeopleArrows,
    fadPhonePlus,
    fadRandom,
    fadSignalAlt,
    fadSignalAlt1,
    fadSignalAlt2,
    fadSignalAlt3,
    fadSync,
    fadTrash,
    fadUserCheck,
    fadUserSlash,
    fadUsersSlash,
    fadUserTimes,
  )
  Vue.component('FAI', FontAwesomeIcon)
}
